<template>
  <DashboardSubsection :title="locale.title">
    <!-- <p class="text-s-regular">
      {{ locale.standard.description }}
      <br><br>
      {{ locale.accelerated.description }}
      <br><br>
      {{ locale.customizable.description }}
      <br><br>
    </p> -->

    <Frequency v-model="model.frequency" />

    <ElRow
      v-if="model.frequency === 'custom'"
      :gutter="20"
    >
      <ElCol :span="12">
        <ElFormItem
          :label="locale.impressionsCount"
          prop="frequencyCount"
        >
          <ElInput
            placeholder="1000"
            v-model="model.frequencyCount"
          />
        </ElFormItem>
      </ElCol>

      <ElCol :span="12">
        <ElFormItem
          class="_transparent-label"
          :label="locale.period"
          prop="frequencyPeriod"
        >
          <ElSelect
            :placeholder="locale.period"
            v-model="model.frequencyPeriod"
          >
            <ElOption
              v-for="item in frequencyPeriods"
              :key="item.id"
              :value="item.id"
              :label="item.title"
            />
          </ElSelect>
        </ElFormItem>
      </ElCol>
    </ElRow>
  </DashboardSubsection>
</template>

<script>
import ElCol from 'element/ElCol'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'
import ElOption from 'element/ElOption'
import ElRow from 'element/ElRow'
import ElSelect from 'element/ElSelect'
import DashboardSubsection from 'components/layouts/DashboardLayout/components/DashboardSubsection'

import Frequency from './fields/Frequency'

export default {
  components: {
    DashboardSubsection,
    Frequency,
    ElFormItem,
    ElRow,
    ElCol,
    ElInput,
    ElSelect,
    ElOption,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => $locale('settings.form.frequency'),
    frequencyPeriods: ({ $store }) => $store.getters['dictionaries/campaignDictionaries']?.frequency_periods,
  },
}
</script>

<style lang="scss" scoped>
._transparent-label {
  ::v-deep {
    .el-form-item__label {
      color: transparent;
    }
  }
}
</style>
